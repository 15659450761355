import React from "react";
import img from "././images/skills-image.jpeg";

export default function Skills() {
  return (
    <>
      <div className="section-skill row margin-bottom-medium" id="skills">
        <div className="skill-content col">
          <h1 className="skill-title ">My Skills</h1>
          <hr></hr>
          <p className="skill-text ">
            I've built user-friendly web applications using React.js, HTML, and
            CSS, showcasing my proficiency in front-end development. My
            familiarity with Git and Github enables effective collaboration and
            version control in team projects.Additionally, I've worked on Java,
            VB.NET, and PHP projects in College.
          </p>
          <hr></hr>

          <div className="skill-container relative">
            <div className="skill-box">
              <span className="title">HTML | CSS</span>
              <div className="skill-bar">
                <span className="skill-per css">
                  <span className="tooltip">80%</span>
                </span>
              </div>
            </div>
            <div className="skill-box">
              <span className="title">Javascript</span>
              <div className="skill-bar">
                <span className="skill-per js">
                  <span className="tooltip">70%</span>
                </span>
              </div>
            </div>
            <div className="skill-box">
              <span className="title">React.JS</span>
              <div className="skill-bar">
                <span className="skill-per react">
                  <span className="tooltip">85%</span>
                </span>
              </div>
            </div>
            <div className="skill-box">
              <span className="title">Node | Express</span>
              <div className="skill-bar">
                <span className="skill-per node">
                  <span className="tooltip">80%</span>
                </span>
              </div>
            </div>
            <div className="skill-box">
              <span className="title">MongoDB | MySQL</span>
              <div className="skill-bar">
                <span className="skill-per mongo">
                  <span className="tooltip">65%</span>
                </span>
              </div>
            </div>
            <div className="skill-box">
              <span className="title">Git</span>
              <div className="skill-bar">
                <span className="skill-per git">
                  <span className="tooltip">80%</span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="image-side relative col">
          <div className="img-bg absolute">
            <div className="skill-image absolute">
              <img alt="Loading.." src={img}></img>
            </div>
          </div>
        </div>
      </div>
      <hr className="hor-line" />
    </>
  );
}
