import React from "react";
import img from "././images/about-image.jpg";
import cv from "./cv/Shambhu__CV_.pdf";

export default function About() {
  return (
    <>
      <div className="section-about row margin-bottom-large" id="about">
        <div className="image-side col">
          <div className="img-bg">
            <div className="about-image">
              <img alt="Loading.." src={img}></img>
            </div>
          </div>
        </div>

        <div className="about-content col">
          <h1 className="about-title ">About Me</h1>
          <hr></hr>
          <p className="about-text margin-bottom-small">
            I'm an aspiring developer eager to explore new opportunities. My
            specialization lies in the MERN stack (MongoDB, Express.js,
            React.js, Node.js), but I also possess a solid understanding of
            various other technologies. I'm great at problem-solving and thrive
            in collaborative team environments. I'm always ready to embrace and
            learn new technologies whenever the need arises.
            <br />
            Thank you!
          </p>

          <a className="btn-primary" href="#contact">
            Hire Me
          </a>
          <a className="btn-download" href={cv} download={cv}>
            Download CV
          </a>
        </div>
      </div>
    </>
  );
}
