import React from "react";
import img from "././images/ss1.png";
import img2 from "././images/ams1.png";
import img3 from "././images/gymrat.png";

export default function Projects() {
  const projectsData = [
    {
      id: 1,
      title: "Football Frenzy",
      image: img,
      description:
        "A web application to buy and sell sports related items like jerseys, balls, phone cases, ornaments etc.",
      githubLink: "https://github.com/shambhuc45/FootballFrenzy_react",
    },
    {
      id: 2,
      title: "Attendance Management System",
      image: img2,
      description:
        "A web application to track and manage attendance of student in an organization created using Node, Express, MongoDB, MUI and React.",
      githubLink: "https://gitlab.com/shambhuc45",
    },
    {
      id: 3,
      title: "Gym Rat",
      image: img3,
      description: "This project is Gym Management system created using php.",
      githubLink: "https://github.com/shambhuc45/Gym_Rat",
    },
  ];
  return (
    <>
      <div className="projects-section" id="projects">
        <div className="projects">
          <h1 className="projects__heading heading-primary">Projects</h1>
          <hr className="hor-line" />

          <div className="projects__container flex">
            {projectsData.map((project) => (
              <div key={project.id} className="card">
                <a
                  href={project.githubLink}
                  target="_blank"
                  rel="noreferrer"
                  className="card__link"
                >
                  <div>
                    <img
                      className="card__image"
                      alt={`Loading..`}
                      src={project.image}
                    ></img>
                  </div>
                  <h2 className="card__title">{project.title}</h2>
                </a>
                <p className="card__description">{project.description}</p>
              </div>
            ))}
          </div>
          <a
            className="btn-more btn-primary margin-top-small"
            href="https://github.com/shambhuc45"
            target="_blank"
            rel="noreferrer"
          >
            See more
          </a>
        </div>
      </div>
      <hr className="hor-line margin-top-medium" />
    </>
  );
}
