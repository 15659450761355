import React from "react";
import { Icon } from "@iconify/react";
import heroimage from "././images/portfolio-min.png";

export default function Herosection() {
  return (
    <div className="split-background margin-bottom-small">
      <div className="left-bg"></div>
      <div className="right-bg"></div>
      <div className="hero-content">
        <h5 className="hello">Hello Everyone</h5>
        <h1 className="name">I'm Shambhu Chaudhary.</h1>
        <div className="job">
          <ul className="job-list flex">
            <li className="items">
              <h3 className="job-title">MERN Stack Developer</h3>
            </li>
            <li className="items icon">
              <a href="#about">
                <Icon
                  className="hire-icon"
                  icon="mingcute:arrow-down-fill"
                  width="30"
                />
              </a>
            </li>
          </ul>
        </div>
        <div className="follow">
          <h2 className="follow__heading">Connect with me:</h2>
          <ul className="follow__list flex">
            <li>
              <a
                href="https://www.linkedin.com/in/shambhu-chaudhary-28a42b2a1/"
                target="_blank"
                rel="noreferrer"
              >
                <Icon
                  icon="entypo-social:linkedin-with-circle"
                  width="35"
                  height="35"
                />
              </a>
            </li>
            <li>
              <a
                href="https://gitlab.com/shambhuc45"
                target="_blank"
                rel="noreferrer"
              >
                <Icon icon="ri:gitlab-fill" width="35" height="35" />
              </a>
            </li>
            <li>
              <a
                href="https://github.com/shambhuc45/"
                target="_blank"
                rel="noreferrer"
              >
                <Icon
                  icon="ant-design:github-outlined"
                  width="35"
                  height="35"
                />
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/shambhu.chaudhary.16100921/"
                target="_blank"
                rel="noreferrer"
              >
                <Icon icon="ic:round-facebook" width="35" height="35" />
              </a>
            </li>
          </ul>
        </div>
        <img
          className="hero-image absolute"
          alt="Loading.."
          src={heroimage}
        ></img>
      </div>
      <hr></hr>
    </div>
  );
}
