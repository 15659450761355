import React, { useState } from "react";
export default function Navigation() {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  const closeNavigation = () => {
    setIsChecked(false);
  };
  return (
    <>
      <div className="navigation">
        <input
          type="checkbox"
          name="nav-check"
          id="nav-check"
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        <div className="check-elements">
          <div className="nav-bg">&nbsp;</div>
          <nav className="nav-bar">
            <ul className="nav-list absolute center text-center">
              <li className="nav-items">
                <a href="/" className="nav-links">
                  <span>01</span>Home
                </a>
              </li>
              <li className="nav-items">
                <a
                  href="#about"
                  className="nav-links"
                  onClick={closeNavigation}
                >
                  <span>02</span>About
                </a>
              </li>
              <li className="nav-items">
                <a
                  href="#education"
                  className="nav-links"
                  onClick={closeNavigation}
                >
                  <span>03</span>Education
                </a>
              </li>
              <li className="nav-items">
                <a
                  href="#skills"
                  className="nav-links"
                  onClick={closeNavigation}
                >
                  <span>04</span>Skills
                </a>
              </li>
              <li className="nav-items">
                <a
                  href="#projects"
                  className="nav-links"
                  onClick={closeNavigation}
                >
                  <span>05</span>Projects
                </a>
              </li>
              <li className="nav-items">
                <a
                  href="#contact"
                  className="nav-links"
                  onClick={closeNavigation}
                >
                  <span>05</span>Contact
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <label htmlFor="nav-check" className="nav-btn">
          <span className="nav-icon">&nbsp;</span>
        </label>
      </div>
    </>
  );
}
