import React from "react";
import { Icon } from "@iconify/react";
import img from "././images/contact1.jpg";

export default function Contact() {
  const emailAddress = "shambhuc45@gmail.com";
  return (
    <>
      <div
        className="section-about row margin-bottom-large contact-container"
        id="contact"
      >
        <div className="image-side col">
          <div className="img-bg">
            <div className="about-image">
              <img alt="Loading.." src={img}></img>
            </div>
          </div>
        </div>

        <div className="about-content col contact-content">
          <h1 className="about-title ">Contact Me</h1>
          <hr></hr>
          <div className="contact">
            <table className="contact-table">
              <tbody>
                <tr>
                  <td>
                    <span>
                      <Icon icon="bi:phone" width="2.5rem" />
                    </span>
                  </td>
                  <td>+9779844978620, +9779807538919</td>
                </tr>
                <tr>
                  <td>
                    <span>
                      <Icon icon="line-md:my-location-loop" width="2.5rem" />
                    </span>
                  </td>
                  <td> Gwarko, Lalitpur, Nepal</td>
                </tr>
                <tr>
                  <td>
                    <span>
                      <Icon icon="line-md:email-twotone" width="2.5rem" />
                    </span>
                  </td>
                  <td>
                    <a href="mailto:shambhuc45@gmail.com">{emailAddress}</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <hr className="hor-line" />
      <div className="copyright_section">
        <h4 className="copyright">
          <Icon icon="ph:copyright-bold" /> 2023 All rights reserved. Designed
          by Shambhu Chaudhary.
        </h4>
      </div>
      <hr className="hor-line last-line" />
    </>
  );
}
