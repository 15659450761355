import "./App.css";
import About from "./components/About";
import Contact from "./components/Contact";
import Education from "./components/Education";
import Experience from "./components/Experience";
import Herosection from "./components/Herosection";
import Navigation from "./components/Navigation";
import Projects from "./components/Projects";
import Skills from "./components/Skills";

function App() {
  return (
    <div className="App">
      <Navigation></Navigation>
      <Herosection></Herosection>
      <About></About>
      <Education></Education>
      <Skills></Skills>
      <Experience></Experience>
      <Projects />
      <Contact />
    </div>
  );
}

export default App;
