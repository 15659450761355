import React from "react";

const Experience = () => {
  return (
    <>
      <div className="section-education margin-bottom-large" id="education">
        <h1 className="education-title heading-primary">Experience</h1>
        <hr className="hor-line"></hr>
        <div className="education-container row">
          <div className="education-card margin-bottom-medium">
            <h2 className="level">MERN Stack Developer</h2>
            <h5 className="institute">
              Deerwalk Compware, Sifal, Kathmandu, Nepal
            </h5>
            <p className="year">(Jan 2024 - current)</p>
          </div>
          <div className="education-card margin-bottom-medium">
            <h2 className="level">Frontend Developer Internship</h2>
            <h5 className="institute">
              Octopus Infosys Pvt. Ltd., Chitwan, Nepal
            </h5>
            <p className="year">(Dec. 2022 - Feb. 2023)</p>
          </div>
        </div>
      </div>
      <hr className="hor-line"></hr>
    </>
  );
};

export default Experience;
