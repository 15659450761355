import React from "react";

export default function Education() {
  return (
    <>
      <div className="section-education margin-bottom-large" id="education">
        <hr className="hor-line"></hr>
        <h1 className="education-title heading-primary">Education</h1>
        <hr className="hor-line"></hr>
        <div className="education-container row">
          <div className="education-card margin-bottom-medium">
            <h2 className="level">Bachelor Of Computer Application</h2>
            <h5 className="institute">
              Oxford College Of Engineering and Management, Gaindakot, Nepal
            </h5>
            <p className="year">(2019-2023)</p>
          </div>
          <div className="education-card margin-bottom-medium">
            <h2 className="level">+2 Science</h2>
            <h5 className="institute">
              Madhyabindu Multiple Campus, Kawasoti, Nepal
            </h5>
            <p className="year">(2016-2018)</p>
          </div>
          <div className="education-card margin-bottom-medium">
            <h2 className="level">SLC</h2>
            <h5 className="institute">
              Janata Higher Secondary School, Danda, Nepal
            </h5>
            <p className="year">2016</p>
          </div>
        </div>
      </div>
      <hr className="hor-line"></hr>
    </>
  );
}
